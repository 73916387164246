import React from 'react'
import './index.scss'
import { Link } from 'gatsby'
import { Button } from '@utils/types'

type Props = {
  button: Button
  className?: string
  onClick?: (event) => void
}

const ButtonComponent = ({ button, className = '', ...props }: Props) => {
  const { title, slug, type } = button

  return (
    <Link
      to={slug?.current}
      className={`btn-com btn-com-${type.toLowerCase()} ${className}`}
      {...props}
    >
      {' '}
      <span>{title}</span>
      <span>{title}</span>
    </Link>
  )
}

export default ButtonComponent
