import React, { useEffect, useState, useRef } from "react"
import "./index.scss"

const FadeInSection = ({ children, timeout, classes = "" }) => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setTimeout(
          () => {
            if (entry.isIntersecting) {
              setVisible(entry.isIntersecting)
            }
          },
          timeout ? timeout : 0
        )
      })
    })
    if (domRef?.current) {
      observer.observe(domRef.current)
    }
    return () => {
      if (domRef?.current) observer.unobserve(domRef.current)
    }
  }, [domRef])

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""} ${classes}`}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export default FadeInSection
